$breakpoints: ('xs-phone': 320px,
    'phone': 480px,
    'tablet': 768px,
    'desktop': 1024px,
    'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
    'print': 'print',
    'handheld': 'handheld',
    'landscape': '(orientation: landscape)',
    'portrait': '(orientation: portrait)',
    'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
    'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/forms';
@import 'base/buttons';

@import 'layout/grid';



//global app styles

#root {
    overflow-x: hidden;
}

#root,
.app,
.app-layout {
    height: 100%;
}

#main {
    display: flex;
    flex-direction: column;
    flex: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-heading;
    line-height: $line-height-heading;
}

h1 {
    font-size: 26px;

    @include media('>=desktop') {
        font-size: 28px;
    }
}

h2 {
    font-size: 24px;

    @include media('>=desktop') {
        font-size: 30px;
    }
}

h3 {
    font-size: 22px;

    @include media('>=desktop') {
        font-size: 28px;
    }
}

h4 {
    font-size: 20px;

    @include media('>=desktop') {
        font-size: 26px;
    }
}

h5 {
    font-size: 18px;

    @include media('>=desktop') {
        font-size: 22px;
    }
}

ul {
    @extend %list-reset;
}

img {
    max-width: 100%;
    height: auto;
}

.ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.underline-none {
    text-decoration: none !important;
}

.loader-main {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.date-time {
    font-size: 12px;
    white-space: nowrap;
    color: $lighten-gray;
    margin-bottom: 18px;
}

.nav-opener {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $primary-color;
    min-width: 30px;
    height: 30px;
    font-size: 18px;
    background-color: $lightening-gray;
    margin: -5px 10px 0 0;
    border: solid rgba(white, .2);
    border-width: 1px 1px 1px 0;
    border-radius: 0 4px 4px 0;

    @include media('>=1200px') {
        display: none;
    }

    &.absolute {
        position: absolute;
        right: -40px;
        top: 13px;

    }
}

.no-data-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    height: 70vh;

    @include media('>=desktop') {
        font-size: 25px;
    }

    .icon {
        display: block;
        font-size: 100px;
        color: #ccc;

        @include media('>=desktop') {
            font-size: 150px;
        }
    }
}

// antd global styles

.ant-layout-sider-children {
    position: relative;
}

.ant-notification {

    .ant-notification-notice-message,
    .ant-notification-notice-description {
        color: $charleston-green
    }
}

.ant-dropdown-arrow {
    border-radius: 0;
}


.holder-date-logo {
    padding: 20px 30px;
    margin-bottom: 5px;
}

.logo {
    display: flex;
    align-items: center;

    .logo-box {
        max-width: 115px;
        margin: 0 auto;
    }

    .logo-text {
        font-size: 20px;
        color: $white;
    }
}

//global tables styles

.no-data-box {
    position: relative;
    padding-bottom: 28px;
}

.ant-table-empty {
    .ant-empty-description {
        display: none;
    }

    .no-data-text {
        position: absolute;
        left: 50%;
        bottom: 26px;
        transform: translateX(-50%);
        color: $text-color;
    }
}

.ant-table-wrapper {
    overflow-x: auto;
}

.ant-table {
    // color: $lighten-gray;
    line-height: 1.2;

    .icon-delete {
        font-size: 16px;
        font-weight: 600;
    }

    &.ant-table-middle {

        @include media('<tablet') {
            min-width: 1100px !important;
        }

        @include media('<desktop') {
            min-width: 1300px;
            font-size: 12px;
        }

        .table-posts & {
            @include media('<1600px') {
                min-width: 1300px;
                font-size: 12px;
            }
        }

        @include media('<1800px') {
            font-size: 13px;
        }

    }

    a {
        // color: $white;
        font-weight: 600;

        &:hover {
            // color: $primary-color;
        }

        &.table-link-underline {
            @extend %underline;
        }
    }

    th.ant-table-cell {
        white-space: nowrap;
        font-weight: 600;
        text-transform: uppercase;
        padding-left: 10px !important;
        padding-right: 10px !important;
        border-radius: 0 !important;

        @include media('>=1601px') {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        .ant-table-column-title {
            @extend .ellipsis;
        }
    }

    td.ant-table-cell {

        @include media('>=1601px') {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        &:last-child {
            padding: 0 !important;
        }

        .title {

            &.opener {
                @include animate(color);
                cursor: pointer;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }

    .ant-table-column-sorter {
        color: #484B4D;

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
            font-size: 10px;
        }
    }

    .ant-empty-normal {
        color: $white;
    }

    .ant-table-tbody {
        >tr {
            &.ant-table-placeholder {
                &:hover {
                    >td {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .ant-table-selection-column {

        .ant-checkbox-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            font-size: inherit;
            z-index: 1;

            .ant-checkbox-checked {

                .ant-checkbox-inner {
                    background-color: $primary-color !important;
                    border-color: $primary-color;
                }
            }

            .ant-checkbox-inner {
                background-color: transparent;
                border-color: $white;
            }

            .ant-checkbox {
                top: 2px;

                +span {
                    margin-left: 6px;
                }
            }
        }
    }
}

.checkbox-outline {
    display: flex;
    align-items: center;
    min-width: 150px;
    font-size: inherit;

    .ant-checkbox-checked {

        .ant-checkbox-inner {
            background-color: $primary-color !important;
            border-color: $primary-color;
        }
    }

    .ant-checkbox-inner {
        background-color: transparent;
        border-color: $white;
    }

    .ant-checkbox {
        top: 0;

        +span {
            margin-left: 6px;
        }
    }
}

.ant-pagination.ant-pagination-mini {
    font-size: 12px;

    .ant-pagination-item-active a {
        color: $white;
    }
}


//global tables filters styles

.dropdown-filter-group {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 8px 11px;
    border-radius: 5px;

    .ant-checkbox-wrapper {
        display: flex;
        margin-bottom: 2px;
    }
}

.table-action-bar {
    margin-bottom: 8px;

    @include media('>=tablet') {
        display: flex;
        align-items: flex-start;
    }

    .file-upload {
        .ant-upload.ant-upload-drag {
            display: block;
        }

        .ant-btn {
            width: 100%;
            max-width: 100%;
        }
    }

    .col {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7px;

        &:last-child {
            @include media('>=tablet') {
                margin-left: auto;
            }
        }

        >* {
            width: calc(50% - 14px);
            margin: 0 7px 10px;

            @include media('>=tablet') {
                width: auto;
            }
        }
    }

    .table-filter-form {
        width: 100%;
        margin: 0;

        @include media('>=tablet') {
            width: auto;
        }


        >* {
            width: calc(50% - 14px);
            margin: 0 7px 10px;

            @include media('>=tablet') {
                width: auto;
            }
        }
    }

    .ant-btn {

        &.ant-btn-default {
            text-transform: none;
        }

        &.ant-btn-link {
            min-width: auto;
            align-items: center;
            text-transform: none;

            @include media('<desktop') {
                border: 1px solid $text-color;
            }

            &:hover {
                @include media('<desktop') {
                    border-color: $primary-color;
                }


                span {
                    color: $primary-color !important;
                    border-color: $primary-color !important;
                }
            }

            span {
                @include animate(border-bottom);
                color: $text-color !important;
                line-height: 1.2;
                transition: all 0.3s ease-in-out;

                @include media('>=desktop') {
                    border-bottom: 1px solid $text-color;
                }
            }
        }
    }
}


//global tables fields styles



.table-skeleton {
    display: block;
    border-radius: 6px;
    height: 17px !important;

    &.one-quarter {
        width: 25%;
    }

    &.half {
        width: 50%;
    }

    &.three-quarters {
        width: 75%;
    }

    &.full {
        width: 100%;
    }

    span {
        width: 100% !important;
        height: inherit !important;
    }

    &+.table-skeleton {
        margin-top: 10px;
    }
}

// links

.tag-main {
    display: inline-block;
    background-color: $lighten-gray;
    text-align: center;
    font-size: 12px;
    min-width: 75px;
    // color: $lighten-gray !important;
    font-weight: normal !important;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    border-radius: 20px;
    padding: 2px 12px;
    margin-right: 14px !important;
    margin-bottom: 4px;

    @include media('>=tablet') {
        font-size: 14px;
    }

    .ant-tag-close-icon {
        color: inherit;
    }
}

.tag-link {
    &:hover {
        background-color: #C4C6C8;
        color: $primary-color !important;
    }
}

.ant-dropdown-open {
    .arrow-down {
        transform: rotate(180deg) translateY(3px);
    }
}

.ant-dropdown {

    .ant-dropdown-menu {
        padding: 10px 0;
    }


    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        font-size: 12px;
        text-transform: uppercase;
        color: $charleston-green;
        padding: 2px 20px;
    }

    .ant-dropdown-menu-item {

        &:hover {
            color: $primary-color;
            background-color: transparent;
        }
    }
}


.disabled-entity {
    color: $charleston-green !important;
    opacity: 0.5;
    cursor: not-allowed;
}


.drop-more-menu {
    min-width: 130px !important;


    &.action-menu {
        .ant-dropdown-menu {
            padding: 10px 0;
            background-color: #F3F4F4;
        }
    }

    .ant-dropdown-menu {
        padding: 4px 0;
    }

    .ant-dropdown-menu-item-disabled {
        color: $charleston-green !important;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        font-size: 14px;
        text-transform: none;
        color: $charleston-green;
    }

    .ant-dropdown-menu-item {
        padding: 5px 15px;
        margin-bottom: 2px;

        &:hover {
            color: $primary-color;
            background-color: transparent;
        }

        .anticon {
            font-size: 20px;
            margin-right: 8px;
        }
    }
}

.btn-more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 28px;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.2s ease-in-out;

    &:hover {
        background-color: rgba(255, 255, 255, 0.06);
    }
}

//global tab styles

.ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
    cursor: pointer;
}

.ant-tabs {
    .ant-tabs-nav {
        font-weight: 600;
    }
}


.ant-tabs {
    .ant-tabs-nav {
        position: absolute !important;
        top: -48px;

        &:before {
            display: none;
        }
    }
}

.ant-tabs-nav-operations {
    display: none !important;
}


.form-btn-holder {
    display: flex;
    padding: 10px 0 10px;

    @include media('>=tablet') {
        padding: 38px 0 20px;
    }

    .ant-btn {
        &:first-child {
            margin-right: 5px;
        }
    }

    .btn-right {
        margin-left: auto;
    }
}

.model-form {
    .form-item-switch {

        .ant-form-item-row {
            flex-flow: row;
            align-items: center;
            flex-direction: row;

            .ant-form-item-control {
                margin-left: 10px;
            }
        }

    }
}

//popup

.ant-modal,
.ant-drawer {

    .ant-modal-close,
    .ant-drawer-close {
        color: $charleston-green;
    }
}

.filters-form {
    .ant-btn {
        font-size: 13px;
    }
}

.ant-modal,
.filters-form {
    .ant-select-multiple {
        .ant-select-selection-item {
            background: rgba(34, 43, 51, 0.15);
        }
    }
}

.ant-modal {
    color: $charleston-green;
    font-weight: 600;

    .ant-modal-content {
        background-color: #F2F3F3;
        padding: 25px 20px;
    }

    .ant-modal-body {
        text-align: center;
        font-size: 16px;
        padding-bottom: 0;

        p {
            margin-bottom: 8px;
        }
    }

    .ant-modal-footer {
        display: flex;
        justify-content: center;
        border: none;
    }
}

.drawer-standard {
    color: $charleston-green;

    .ant-drawer-body {
        background-color: #F2F3F3;
    }

    .modal-title {
        font-size: 16px;
    }

    .ant-drawer-header {
        position: absolute;
        left: 0;
        right: 0;
        background: transparent;
    }

    .ant-drawer-close {
        position: absolute;
        top: 32px;
        right: 0;
    }

    .ant-drawer-body {
        padding-top: 30px;
        padding: 30px 18px 18px 18px;
    }
}


.modal-standard {

    .ant-btn {
        min-width: 110px;
    }

    &.modal-delete {

        .ant-btn-primary {
            margin-left: 30px !important;
        }
    }
}

.modal-form {

    .btn-text {
        cursor: pointer;
        margin-left: 15px;
    }

    .ant-btn {
        min-width: 110px;
    }

    &.filters {
        .modal-title {
            text-align: left;
        }

        .ant-select-selection-item {

            &[title] {
                font-weight: 300;
                color: rgba(34, 43, 51, 0.6);
            }
        }
    }

    .modal-title {
        display: block;
        text-align: center;
        margin-bottom: 13px;
    }

    .ant-modal-content {
        padding: 17px 23px 30px;
    }

    .ant-modal-body {
        text-align: left;
        padding: 0;
    }

    .ant-form {
        label {
            font-size: 12px;
        }
    }

    .select-model-modal-button,
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-picker,
    .ant-input {
        border: 1px solid rgba(34, 43, 51, 0.15) !important;

        &::placeholder {
            font-weight: 400;
            color: rgba(34, 43, 51, 0.6);
        }
    }

    .ant-form-item-label>label {
        color: rgba(34, 43, 51, 0.6);

    }

    .form-btn-holder {
        padding: 5px 0 0;

        @include media('<widescreen') {
            .ant-btn {
                font-size: 12px;
                line-height: 1.8;
            }
        }
    }


    .delete-form {
        p {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: $charleston-green;
            margin-bottom: 20px;
        }
    }

    .line {
        display: block;
        margin: 0 -23px;
        height: 1px;
        background-color: rgba(34, 43, 51, 0.15);
        margin-bottom: 13px;

    }

    .download-file-name {
        color: black;

        &:hover {
            color: $primary-color;
        }
    }
}

.ant-picker-clear:hover {
    color: #b6c8d8;
}


.view-box-field {
    display: flex;
    align-items: center;
    font-weight: 600;

    &:hover {
        .btn-view {
            opacity: 1;
        }
    }

    .count {
        margin-right: 10px;
    }

    .btn-view {
        font-size: 12px;
        color: $white !important;
        text-transform: uppercase;
        background-color: #585E64;
        transition: opacity .2s ease-in-out;
        border-radius: 5px;
        margin-left: 3px;
        padding: 2px 13px;
        opacity: 0;
    }
}

.action-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 600;

    @include media('>=desktop') {
        display: inline-block;
    }

    &.indents {
        @include media('>=desktop') {
            padding: 24px 34px;
            margin-bottom: 10px;
        }
    }

    >li {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 16px;
        transition: color 0.3s ease;
        margin-right: 20px;

        @include media('>=desktop') {
            margin-right: 0;
        }

        &:hover {
            color: $primary-color;
        }
    }

    .anticon {
        font-size: 28px;
        margin-right: 10px;
    }
}


.filter-count {
    display: inline-block !important;
    width: 20px;
    height: 20px;
    color: $white;
    background-color: $primary-color;
    font-size: 12px;
    line-height: 20px;
    border-radius: 50%;
    margin-top: 1px;
    margin-left: 10px;
}


.file-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    // width: 140px;

    @include media('>=desktop') {
        width: 140px;
    }

    .modal-form & {
        width: 100%;
    }

    >li {
        margin-bottom: 19px;
    }

    .ant-btn-sm {
        min-width: 92px !important;
        margin-top: 3px;
        margin-left: 10px;

        .modal-form & {
            margin-left: 0;
        }
    }

    .label-text {
        margin-left: 10px;
        margin-bottom: 3px !important;

        .modal-form & {
            display: block;
            color: rgba(34, 43, 51, 0.6);
            margin-left: 0;
        }
    }

    .link-text {
        border-bottom: none;
    }


    a {
        .anticon {
            margin-left: 8px;

            .modal-form & {
                margin-left: 0;
            }
        }
    }

    .anticon {
        font-size: 22px !important;
    }
}


.download-file-list {

    .ant-upload.ant-upload-drag {
        display: block;
    }

    .upload-file,
    .download-file {
        min-width: 146px;

        .link-text {
            text-transform: capitalize;
        }
    }

    >li {
        margin-bottom: 6px;
    }

    .upload-file {
        margin-left: 1px;

        .link-text {
            color: rgba(255, 255, 255, 0.4);
        }
    }
}


.upload-file,
.download-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-width: 125px;
    margin: 0 -5px 2px;


    a {
        color: $white;

        .modal-form & {
            color: $primary-color;
        }

        &:hover {
            color: $primary-color;

            .link-text {
                color: currentColor;
            }

            &:first-child {
                span {
                    color: currentColor;
                }
            }
        }

        &:first-child {
            span {
                color: $white;

                .modal-form & {
                    color: $primary-color;
                }
            }
        }
    }

    .link-text {
        display: inline-block;
        font-weight: 600;
    }


    .icon-delete-file {

        .anticon {
            color: $charleston-green;

            &:hover {
                color: rgba($charleston-green, .5);
            }
        }
    }

    .anticon {
        display: inline-flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.4);
        font-size: 20px;
        padding: 0 5px;

        &:hover {
            color: $white;
        }
    }
}


.upload-file {

    a {
        color: rgba(255, 255, 255, 0.4);
    }
}


.download-file {

    .link-text {
        text-decoration: underline;

    }
}


.file-upload {
    .anticon-spin {
        font-size: 14px !important;
    }
}

.ant-upload {
    &.ant-upload-drag {
        text-align: left;
        background: transparent;
        border: 0;

        &.ant-upload-drag-hover {

            * {
                color: $primary-color;
            }

            .ant-btn-primary {
                border-color: red;
                background: #7a1b54;
            }

            .ant-btn-default {
                border-color: $white;
                background: darken($light-gray, 15%);
            }
        }

        .ant-upload {
            padding: 0;
        }

        .anticon-plus {
            color: inherit !important;
        }
    }
}


.crafts-list {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(3, 34px);
}

.craft-tooltip,
.model-tooltip {
    .ant-tooltip-content {
        transform: translateY(12px);
    }
}


.color-white {
    color: $white;
}

.color-green {
    color: $green;
}

.color-yellow {
    color: $yellow;
}

.color-red {
    color: $red;
}

.color-disabled {
    // cursor: not-allowed;
    color: rgba(255, 255, 255, 0.15);
}

.unassigned {
    display: flex;
    align-items: center;

    .anticon {
        font-size: 20px;
        margin-right: 10px;
    }
}


.arrow-right {
    font-size: 20px;
    transform: rotate(-90deg);
}


.keys-list {
    color: $white;
    margin-bottom: 20px;

    @include media('>=tablet') {
        margin-bottom: 0;
    }

    &.active {
        >li {
            &:last-child {
                display: block;
            }
        }

    }

    >li {
        margin-bottom: 5px;

        &:first-child {
            display: block !important;
        }

        &:last-child {
            display: none;
            margin-bottom: 0;
        }
    }

    .title {
        display: block;
        color: $white;
        margin-bottom: 8px;
    }

    .holder {

        flex-wrap: wrap;
        align-items: center;
        margin: 0 -4px;

        @include media('>=tablet') {
            display: flex;
        }

        >* {
            padding: 0 4px;

            &:first-child {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
        }
    }

    .btn-holder {
        margin-top: 8px;
        // margin-bottom: 20px;

        @include media('>=tablet') {
            padding: 0 11px;
            // margin-bottom: 0;
        }

        .ant-btn {
            position: relative;
            margin-right: 15px;
            width: 90px;
            height: 36px !important;

            .ant-btn-loading-icon {
                position: absolute;
                top: 0;
                left: 5px;
            }
        }
    }

    .anticon {
        font-size: 22px;
        color: rgba(255, 255, 255, 0.4);
        cursor: pointer;
        transition: color 0.3s ease;
        margin-top: 6px;

        &:hover {
            color: $white;
        }
    }
}

.add-user-form {

    .ant-checkbox-wrapper {
        text-transform: capitalize;
        font-size: 14px !important;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}

.group-checkbox {
    position: relative;
    display: block !important;
    font-weight: normal;
    overflow-y: auto;
    padding: 15px 0 9px !important;
    margin-bottom: 10px !important;

    .ant-checkbox-group-item {
        display: flex;
        margin-bottom: 5px;
    }
}


.group-radio {
    display: flex !important;
    flex-direction: column;
    position: relative;
    font-weight: normal;
    overflow-y: auto;
    padding: 15px 0 9px !important;
    margin-bottom: 10px !important;

    .ant-radio-wrapper {
        margin-bottom: 5px;
    }
}

.loader-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-action-assign-create {

    .ant-row,
    .multi-select-subcontractor {
        display: block;
    }

    .add-field-list {
        li {
            position: relative;


            &:first-child {
                .anticon-close-circle {
                    display: none !important;
                }
            }

            &:last-child {
                .anticon-close-circle {
                    display: block;
                }
            }

            .anticon-close-circle {
                display: none;
                position: absolute;
                top: 10px;
                right: -14px;
                transition: color 0.2s ease-in-out;

                &:hover {
                    color: $charleston-green;
                }
            }
        }
    }
}

.group-rigths {
    span {
        +span {
            &:before {
                content: ' & ';
            }
        }
    }
}


.select-model-modal-button {
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    line-height: 1;
    justify-content: space-between;
    background-color: $lighten-gray;
    color: $input-placeholder-color;
    width: 100%;
    height: 36px;
    padding: 6px 13px;
    border-radius: 6px;

    .placeholder-text {
        color: $input-placeholder-color;
    }

    .anticon {
        font-size: 22px;
        transform: rotate(-90deg);
        margin-right: -7px;

        &.arrow-down {
            transform: rotate(0deg);
        }

        &.anticon-sync {
            font-size: 18px;
            margin-right: -2px;
        }
    }
}


.page-last-minute,
.page-user {

    .top-table-text {
        display: inline-block;
        color: white;
        font-size: 18px;
        margin: 5px 0 25px;
    }

    .select-model-modal-button {

        @include media('>=tablet') {
            width: 301px;
            margin-right: 10px;
        }
    }
}

.btn-export-logs {
    .ant-btn-loading-icon {
        color: white;
    }
}

.filter-users-log {

    .table-filter-form {
        display: flex;
    }

    .col {
        &:first-child {
            >* {

                @include media('<tablet') {
                    width: 100%;
                }
            }
        }
    }
}

.flex-field-box {
    display: flex;
    align-items: center;

    .col {
        padding: 0 7px;
    }

    .icon {
        font-size: 17px;
        font-weight: 700;
        cursor: pointer;
        color: #44D13F;
    }
}

.drop-download-report {

    .ant-dropdown-menu-item {
        flex-wrap: wrap;
    }

    .title {
        width: 100%;
        display: block;
        font-size: 13px;
        color: $primary-color;
        margin-bottom: 5px;
    }
}

.download-file-info {
    min-width: 1px;
    flex: 1 1;

    .download-file-name {
        display: flex;
        align-items: center;
        flex: 1 1;

        .file-name {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.upload-document-holder {
    // max-width: 1300px;
    margin: 0 auto 20px;


    .ant-upload-drag-hover {

        .upload-document-box {
            background-color: rgba($primary-color, .05);
            border: 1px dashed $primary-color;
        }
    }

    .upload-document-box {
        @include animate(opacity left right);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        height: 50px;
        background-color: rgba(white, .1);
        border: 1px dashed rgba(white, .5);

        @include media('>=tablet') {
            font-size: 35px;
            height: 65px;
        }

        @include media('>=widescreen') {
            font-size: 43px;
            height: 90px;
        }

        &:hover {
            opacity: .6;
        }

        .anticon {
            margin-right: 12px;
        }

        .text {
            margin-top: 6px;
            font-size: 14px;

            @include media('>=widescreen') {
                font-size: 16px;

            }
        }

    }
}

.custom-close-modal-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 16px;
    color: black;
    cursor: pointer;
}

.row-dragging {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.event-attachments {
    .ant-table td.ant-table-cell.drag-visible {

        &:first-child {
            padding: 8.5px 20px 8.5px 5px !important;
        }
    }
}

.edit-quarter-form {
    border: 1px solid #ccc;
    border-radius: 6px;

    .ant-form-item {
        margin-bottom: 0;
    }
}

.charts-list {
    padding: 20px 0;
    color: rgba(0, 0, 0, 0.45);

    @include media('>=tablet') {
        grid-gap: 60px !important;
    }

    @include media('>=1800px') {
        grid-template-columns: repeat(4, 1fr) !important;
    }


    .header {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
    }

    canvas {
        width: 100% !important;
    }
}

.posts-top-list,
.users-top-list {

    >li {
        &:hover {
            .ant-card-meta-title {
                strong {
                    color: $primary-color;
                }
            }
        }

    }


    .ant-card-body {
        padding: 18px;

        .ant-avatar-lg {
            width: 60px;
            height: 60px;
        }

        .ant-card-meta-title {
            line-height: 1.2;
            margin-bottom: 0;

            strong {
                @include animate(color);
            }
        }

        .ant-card-meta-description {
            line-height: 1.3;
        }
    }
}

.edit-user-question-form {
    .ant-input-textarea-show-count::after {
        position: absolute;
        right: 0;
        top: -22px;
    }
}